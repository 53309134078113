// utils/reCaptcha.js
export const reCaptchaSrc = "https://www.google.com/recaptcha/api.js?render=6LcGPPApAAAAAGULWdxx1JBQ54wpK_Yjhb7Ho5ed";

export const loadReCaptchaScript = (src) => {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      existingScript.onload = resolve;
      existingScript.onerror = reject;
      if (existingScript.readyState === 'complete') {
        resolve();
      }
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

export const executeCaptcha = async () => {
  if (window.grecaptcha && window.grecaptcha.execute) {
    try {
      const token = await window.grecaptcha.execute('6LcGPPApAAAAAGULWdxx1JBQ54wpK_Yjhb7Ho5ed', { action: 'submit' });
      return token;
    } catch (error) {
      console.error('Error executing reCAPTCHA', error);
      return null;
    }
  } else {
    console.error('reCAPTCHA not loaded or execute function not available');
    return null;
  }
};
