import React, { useState, useContext, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { AuthContext } from '../components/AuthContext';
import styles from '../styles/Header.module.scss';
import CommonSnackbar from '../components/CommonSnackbar';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import { loadReCaptchaScript, reCaptchaSrc } from '../utils/recaptcha';
import Logo from '../assets/icons/ammohawk-logo.webp';

const Header = ({ showSnackbar }) => {
  const router = useRouter();
  const { authState, setAuthState } = useContext(AuthContext);

  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    type: "",
  });

  const handleClick = (newState) => {
    setSnackbar({ open: true, ...newState });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    loadReCaptchaScript(reCaptchaSrc).then(() => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          console.log('reCAPTCHA is ready');
        });
      }
    }).catch((err) => {
      console.error("Failed to load reCAPTCHA script", err);
    });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.dispatchEvent(new Event("storage"));
    setAuthState({ token: null, user: null });
  };

  return (
    <>
      <CommonSnackbar snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <LoginModal openLogin={openLogin} handleCloseLogin={() => setOpenLogin(false)} showSnackbar={showSnackbar} />
      <RegisterModal openRegister={openRegister} handleCloseRegister={() => setOpenRegister(false)} showSnackbar={showSnackbar} />

      <div className={styles.header}>
        <Link href="/" legacyBehavior passHref>
          <a className={styles.headerLogo}>
            <Image src={Logo} alt="logo" width="150" height="auto" unoptimized />
          </a>
        </Link>
        <div className={styles.headerNav}>
          <Link href='/blogs' legacyBehavior passHref>
            <a className={styles.headerLink}>Blog</a>
          </Link>
          {!authState?.user ? (
            <>
              <button onClick={() => setOpenLogin(true)} className={styles.headerLogin}>
                Login
              </button>
              <button onClick={() => setOpenRegister(true)} className={styles.headerLogin} style={{ marginLeft: '18px' }}>
                Register
              </button>
            </>
          ) : (
            <>
              <p style={{ cursor: 'pointer' }} onClick={() => router.push('/profile')}>
                @{authState.user.username}
              </p>
              <button
                onClick={handleLogout}
                className={`${styles.headerLogin} ${styles.logout}`}
              >
                Logout
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
