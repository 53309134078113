import axios from "axios";

export const getJWTToken = async () => {
  try {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      return null;
    }
    let token = JSON.parse(tokenData).access;

    return token;
  } catch (err) {
    try {
      console.log(err);
      let refreshToken = JSON.parse(localStorage.getItem("token")).refresh;
      if (err.response && err.response.status === 401) {
        const res = await axios.post(
          `https://backend.ammohawk.com/api/token/refresh`,
          {
            refresh: refreshToken,
          }
        );
        localStorage.setItem(
          "token",
          JSON.stringify({
            access: res.data.access,
            refresh: refreshToken,
          })
        );
        return res.data.access;
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.removeItem("token");
      }
      return null;
    }
  }
};

export const getData = async () => {
  const token = await getJWTToken();
  
  if (!token) {
    // Handle the case where there is no token
    return null;
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  
  try {
    const res = await axios.get(`https://backend.ammohawk.com/api/users/me`, config);
    return res.data;
  } catch (err) {
    console.error("Error fetching data", err);
    return null;
  }
};

