// components/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { getJWTToken, getData } from '../utils/getData';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ token: null, user: null });

  useEffect(() => {
    const fetchData = async () => {
      const token = await getJWTToken();
      if (token) {
        const user = await getData();
        setAuthState({ token, user });
      } else {
        setAuthState({ token: null, user: null });
      }
    };

    fetchData();

    const handleStorageChange = () => {
      fetchData();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
