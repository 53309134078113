import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Layout from "../components/Layout";
import "../styles/Footer.scss";
import '../styles/BrowseComponents.scss';
import "../styles/Product.scss";
import "../styles/Profile.scss";
import "../styles/RegisterEmail.scss";
import "../styles/TermsOfService.scss";
import "../styles/PrivacyPolicy.scss";
import "../styles/ContactUs.scss";
import "../styles/Blogs.scss";
import "../styles/Blog.scss";
import "../styles/AffiliateDisclosure.scss";
import "../styles/About.scss";
import Head from "next/head";
import Script from "next/script";
import "../styles/globals.scss";
import { SnackbarProvider } from "notistack";
import CommonSnackbar from "../components/CommonSnackbar";
import Header from "../components/Header";
import { AuthProvider } from '../components/AuthContext';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_ANALYTICS_ID;

function MyApp({ Component, pageProps }) {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'info' });
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag('config', GA_TRACKING_ID, { page_path: url });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, type = 'info') => {
    setSnackbar({ open: true, message, type });
    console.log(`Snackbar type: ${type}, message: ${message}`);
  };

  return (
    <>
      <Head>
        <title>AmmoHawk</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Find the best ammunition prices and availability from top ammo suppliers and vendors | 5.56 9mm .308 12 gauge 300 blackout" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}');
          `,
        }}
      />
      <AuthProvider>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
        >
          <Layout>
            <Header showSnackbar={showSnackbar} />
            <Component {...pageProps} showSnackbar={showSnackbar} />
          </Layout>
          <CommonSnackbar snackbar={snackbar} handleCloseSnackbar={handleSnackbarClose} />
        </SnackbarProvider>
      </AuthProvider>
    </>
  );
}

export default MyApp;