import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { executeCaptcha } from '../utils/recaptcha';
import styles from '../styles/LoginModal.module.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'rgb(40, 40, 40)',
  border: 'none',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
};

const LoginModal = ({ openLogin, handleCloseLogin, showSnackbar }) => {
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    const token = await executeCaptcha();

    if (!token) {
      showSnackbar('Failed to verify reCAPTCHA. Please try again.', 'error');
      return;
    }

    try {
      const res = await axios.post('https://backend.ammohawk.com/api/users/login', {
        username: loginUsername,
        password: loginPassword,
        recaptcha_token: token,
      });

      localStorage.setItem('token', JSON.stringify(res.data.token));
      window.dispatchEvent(new Event('storage'));

      showSnackbar('Login Successfully!', 'success');
      handleCloseLogin();
    } catch (err) {
      showSnackbar('User not found! Make sure the username and password is correct.', 'error');
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openLogin}
      onClose={handleCloseLogin}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openLogin}>
        <Box sx={style} className={styles.modal}>
          <div className={styles.modal__title}>Login</div>
          <form onSubmit={handleLogin}>
            <input
              placeholder="Enter your username"
              type="text"
              required
              value={loginUsername}
              onChange={(e) => setLoginUsername(e.target.value)}
              className={styles.input}
            />
            <input
              placeholder="Enter your password"
              required
              type="password"
              value={loginPassword}
              onChange={(e) => setLoginPassword(e.target.value)}
              className={styles.input}
            />
            <p onClick={() => { handleCloseLogin(); showSnackbar({ type: 'register' }); }}>
              Register
            </p>
            <button type="submit" className={styles.button}>Login</button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default LoginModal;
