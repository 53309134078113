
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('./Header'), { ssr: false });
const Footer = dynamic(() => import('./Footer'), { ssr: false });
import Link from 'next/link';
import styles from '../styles/Layout.module.scss';

const Layout = ({ children }) => {
  return (
    <div className={styles.container}>
      <Header />
      <nav className={styles.nav}>
        <ul>
          <li><Link href="/">Home</Link></li>
          <li><Link href="/about">About</Link></li>
          <li><Link href="/contact-us">Contact Us</Link></li>
          <li><Link href="/privacy-policy">Privacy Policy</Link></li>
          <li><Link href="/terms-of-service">Terms of Service</Link></li>
          <li><Link href="/affiliate-disclosure">Affiliate Disclosure</Link></li>
        </ul>
      </nav>
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
