import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { executeCaptcha } from '../utils/recaptcha';
import styles from '../styles/LoginModal.module.scss'; // Reusing the same styles

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'rgb(40, 40, 40)',
  border: 'none',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
};

const RegisterModal = ({ openRegister, handleCloseRegister, handleClick }) => {
  const [registerUsername, setRegisterUsername] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    const token = await executeCaptcha();

    if (!token) {
      setError('Failed to verify reCAPTCHA. Please try again.');
      return;
    }

    if (registerPassword.length >= 8 && /\d/.test(registerPassword) && /[a-zA-Z]/g.test(registerPassword)) {
      if (registerConfirmPassword === registerPassword) {
        try {
          const res = await axios.post('https://backend.ammohawk.com/api/users/register', {
            username: registerUsername,
            email: registerEmail,
            first_name: registerUsername,
            last_name: registerUsername,
            password: registerPassword,
            role: 'USER',
            recaptcha_token: token,
          });

          handleClick({ type: 'success', message: 'Email verification sent successfully' });
          handleCloseRegister();
        } catch (err) {
          handleClick({
            type: 'error',
            message: err.message.includes('40')
              ? err.response.data.email
                ? err.response.data.email
                : err.response.data.username
              : err.message,
          });
        }
      } else {
        setError('Your passwords do not match.');
      }
    } else {
      setError('Your password must be at least 8 characters long and include at least one letter and number!');
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openRegister}
      onClose={handleCloseRegister}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openRegister}>
        <Box sx={style} className={styles.modal}>
          <div className={styles.modal__title}>Register</div>
          <form onSubmit={handleRegister}>
            <input
              placeholder="Enter your username"
              type="text"
              value={registerUsername}
              required
              onChange={(e) => setRegisterUsername(e.target.value)}
              className={styles.input}
            />
            <input
              placeholder="Enter your email"
              required
              type="email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
              className={styles.input}
            />
            <input
              placeholder="Enter your password"
              required
              type="password"
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
              className={styles.input}
            />
            <input
              placeholder="Confirm your password"
              required
              type="password"
              value={registerConfirmPassword}
              onChange={(e) => setRegisterConfirmPassword(e.target.value)}
              className={styles.input}
            />
            {error && <div className={styles.error}>{error}</div>}
            <p onClick={() => { handleCloseRegister(); handleClick({ type: 'login' }); }}>
              Login
            </p>
            <button type="submit" className={styles.button}>Register</button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default RegisterModal;
