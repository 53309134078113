import React from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CommonSnackbar = ({ snackbar, handleCloseSnackbar }) => {
  const { vertical = 'top', horizontal = 'center', open = false, message = '', type = 'info' } = snackbar;

  // Validate type prop
  const validTypes = ['error', 'warning', 'info', 'success'];
  const alertType = validTypes.includes(type) ? type : 'info';


  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleCloseSnackbar}
      key={`${vertical},${horizontal}`}
      autoHideDuration={3000}
    >
      <Alert onClose={handleCloseSnackbar} severity={alertType} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CommonSnackbar;
